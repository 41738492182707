var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0 fill-height text-left",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex fill-height fluid pa-0 ma-0",attrs:{"tile":"","width":"100%"}},[_c('v-scroll-x-transition',{attrs:{"appear":""}},[_c('v-img',{attrs:{"src":require("@/assets/images/bht_clinics.jpg"),"transition":"slide-x-transition","position":"center","width":"100%","height":"300","max-height":"300"}},[_c('v-container',{staticClass:"fill-height justify-center align-end bottom-gradient",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('div',{staticClass:"white--text pb-10"},[_c('span',{staticClass:"pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black",staticStyle:{"text-shadow":"2px 2px 10px black"}},[_vm._v(" Only the best Clinics ")])])])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"boxdivider"})]),_c('v-row',{attrs:{"justify":"center"}},[_c('SearchBar',{attrs:{"data":_vm.clinics},on:{"search":_vm.updateFilteredData}})],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"fill-height container--fluid mt-8 mb-8 mx-5",staticStyle:{"width":"100%"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},_vm._l((_vm.filteredData),function(item,index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"12","sm":"6","md":"4"}},[(item.active)?_c('v-card',{staticClass:"rounded-lg",attrs:{"hover":""}},[_c('div',{staticStyle:{"cursor":"pointer","height":"300px","overflow":"hidden"},on:{"click":function($event){return _vm.showProfile(item.id)}}},[_c('v-img',{staticClass:"rounded-lg white--text align-end justify-start elevation-2",attrs:{"src":item.pictures[0] != null
                      ? item.pictures[0].url
                      : require('@/assets/images/no-image.jpg'),"lazy-src":require('@/assets/images/no-image.jpg'),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","aspect-ratio":"1","height":"300"},on:{"click":function($event){return _vm.showProfile(item.id)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","plain":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.showProfile(item.id)}},model:{value:(_vm.selectedId),callback:function ($$v) {_vm.selectedId=$$v},expression:"selectedId"}},[_c('span',{staticClass:"text-uppercase font-weight-bold",domProps:{"textContent":_vm._s(item.name)}})])],1),(item.basic===1 
                  || item.id == 8 
                  || item.id == 7
                  || item.id == 6
                  || item.id == 5

                 )?_c('img',{staticClass:"superior-derecha",attrs:{"src":require("@/assets/images/icons/basic.png"),"alt":"sello"}}):(item.premium===1
                      || item.id == 14 

                      )?_c('img',{staticClass:"superior-derecha",attrs:{"src":require("@/assets/images/icons/premium.png"),"alt":"sello"}}):_c('img',{staticClass:"superior-derecha",attrs:{"src":require("@/assets/images/icons/member.png"),"alt":"sello"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.excellence===1 
                      || item.id == 14 
                      || item.id == 23
                      || item.id == 24
                      || item.id == 26
                      ),expression:"item.excellence===1 \n                      || item.id == 14 \n                      || item.id == 23\n                      || item.id == 24\n                      || item.id == 26\n                      "}],staticClass:"superior-derecha-segunda",attrs:{"src":require("@/assets/images/icons/escudo_coepris.f8331f99.png"),"alt":"sello1"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.repsabi===1
                      || item.id == 14 
                      || item.id == 23
                      || item.id == 24
                      || item.id == 26                      
                    ),expression:"item.repsabi===1\n                      || item.id == 14 \n                      || item.id == 23\n                      || item.id == 24\n                      || item.id == 26                      \n                    "}],staticClass:"superior-derecha-tercera",attrs:{"src":require("@/assets/images/icons/repssabi.png"),"alt":"sello1"}})],1)]):_vm._e()],1)}),1)],1)],1)]),(_vm.clinics.length === 0)?_c('v-row',{staticClass:"ma-10",attrs:{"justify":"center"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(" "+_vm._s(this.message)+" ")]),_c('div',{staticStyle:{"display":"inline-block","margin-top":"-15px"}},[_c('v-img',{attrs:{"src":require("@/assets/images/loading_new.gif"),"width":"100px"}})],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }